import React, {useState, useMemo} from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Card from '../../../../Card';
import Span from '../../../../atoms/Span';
import Badge from '../../../../atoms/Badge';
import FlexBox from '../../../../atoms/FlexBox';
import Space from '../../../../atoms/Space';
import Width from '../../../../atoms/Width';
import FormLabel from '@mui/material/FormLabel';
import Button from '../../../../atoms/Button';
import Axios from '../../../../../lib/axios'
import Snackbar from '../../../../shared/Snackbar';
import Container from '../../../../atoms/Container';
import MemoTextField from '../MemoTextField';

function index({applications}) {
  const [defaultResults, setDefaultResults] = useState(applications.map(a => ({id: a.id, result_id: a.result_id})))
  const [results, setResults] = useState(defaultResults)
  const [isProcessing, setIsProcessing] = useState(false)
  const [snack, setSnack] = useState({
    type: 'success',
    message: '',
    autoHideDuration: 2000,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
  });

  const handleChange = (e, a) => {
    const statusCopy = [...results]
    const index = statusCopy.findIndex((elem) => elem.id === a.id)
    setResults([...results.filter(s => s.id !== a.id), {id: a.id, result_id: Number(e.target.value)}])
  }
  const isChangedResult = useMemo(() => {
    return defaultResults.filter(r => results.find(result => result.id === r.id).result_id !==r.result_id).length > 0
  }, [defaultResults, results])


  const handleSubmit = () => {
    Axios.put(`/tech/student_grades/update_results`, {results: results})
      .then(ret => {
        setSnack({...snack, type: 'success', message: '合否ステータスを更新しました'})
        setDefaultResults(results)
      })
      .catch(err => {
        setSnack({...snack, type: 'error', message: err.response.data.message})
      })
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({...snack, message: ""})
  };

  const handleCancel = () => {
    setResults(defaultResults)
  }
  return (
    <>
      <Snackbar
        open={snack.message !== ""}
        message={snack.message}
        anchorOrigin={snack.anchorOrigin}
        autoHideDuration={snack.autoHideDuration}
        type={snack.type}
        onClose={handleSnackClose}
      />
      {applications.map(a => (
        <Card key={a.id}>
          <FlexBox width="100%" content={'space-between'} align="start">
            <div>
              <Space margin="0 0 0.5rem 0">
                <FlexBox>
                  {
                    a.status === '受講期間前' && (
                      <Badge
                        backColor="#fffacd"
                        border="1px solid #fffacd"
                        color="#ff8c00"
                      ><Span fontSize="1.2rem">{a.status}</Span></Badge>

                    )
                  }
                  {
                    a.status === '受講期間中' && (
                      <Badge
                        backColor="#C7F2E1"
                        border="1px solid #C7F2E1"
                        color="#00965C"
                      ><Span fontSize="1.2rem">{a.status}</Span></Badge>
                    )
                  }
                  {
                    a.status === '受講期間終了' && (
                      <Badge
                        backColor="#dddada"
                        border="1px solid #dddada"
                        color="#777"
                      ><Span fontSize="1.2rem">{a.status}</Span></Badge>
                    )
                  }
                </FlexBox>
              </Space>
              <div className="mb-3">
                <Span fontSize="2rem">
                  <div dangerouslySetInnerHTML={{ __html: a.applier_name}} />
                </Span>
                <Span fontSize="1.5rem">
                  {a.corporation_name}
                </Span>
              </div>
            </div>
            <FlexBox align="start" height="100%">
              <div dangerouslySetInnerHTML={{ __html: a.candi_link}} />
              <Span color="#005BAC">
                <span className="material-icons">open_in_new</span>
              </Span>
            </FlexBox>
          </FlexBox>
          <FlexBox width="100%">
            <Width size="80%">
              <FlexBox width="100%">
                <Width size="40%">
                  <FlexBox>
                    <Badge><Span fontSize="1.5rem">申込ID</Span></Badge>
                    <Space margin="0 0 0 0.5rem">
                      <Span fontSize="1.5rem">
                        {a.id}
                      </Span>
                    </Space>
                  </FlexBox>
                </Width>
                <Width size="60%">
                  <Space padding="0 0 0 1rem">
                    <FlexBox>
                      <Badge><Span fontSize="1.5rem">申込席数</Span></Badge>
                      <Space margin="0 0 0 0.5rem">
                        <Span fontSize="1.5rem">
                          <div dangerouslySetInnerHTML={{ __html: a.apply_amount}} />
                        </Span>
                      </Space>
                    </FlexBox>
                  </Space>
                </Width>
              </FlexBox>
              <Space margin="1rem 0 0 0">
                <FlexBox width="100%">
                  <Width size="40%">
                    <FlexBox>
                      <Badge><Span fontSize="1.5rem">申込日時</Span></Badge>
                      <Space margin="0 0 0 0.5rem">
                        <Span fontSize="1.5rem">
                          {a.applied_at}
                        </Span>
                      </Space>
                    </FlexBox>
                  </Width>
                  <Width size="60%">
                    <Space padding="0 0 0 1rem">
                      <FlexBox>
                        <Badge><Span fontSize="1.5rem">受講期間</Span></Badge>
                        <Space margin="0 0 0 0.5rem">
                          <Span fontSize="1.5rem">
                            {a.course_period}
                          </Span>
                        </Space>
                      </FlexBox>
                    </Space>
                  </Width>
                </FlexBox>
              </Space>
              <Space margin="1rem 0 0 0">
                <FlexBox width="100%">
                  <Width size="40%">
                    <FlexBox>
                      <Badge><Span fontSize="1.5rem">ステータス</Span></Badge>
                      <Space margin="0 0 0 0.5rem">
                        <Span fontSize="1.5rem">
                          {a.payment_status}
                        </Span>
                      </Space>
                    </FlexBox>
                  </Width>
                  <Width size="60%">
                    <Space padding="0 0 0 1rem">
                      <FlexBox>
                        <Badge><Span fontSize="1.5rem">メールアドレス</Span></Badge>
                        <Space margin="0 0 0 0.5rem">
                          <Span fontSize="1.5rem">
                            {a.applier_mail}
                          </Span>
                        </Space>
                      </FlexBox>
                      </Space>
                  </Width>
                </FlexBox>
              </Space>
              <Space margin="1rem 0 0 0">
                <FlexBox>
                  <FlexBox>
                    <Badge><Span fontSize="1.5rem">コース名</Span></Badge>
                    <Space margin="0 0 0 0.5rem">
                      <Span fontSize="1.5rem">
                        {a.course_name}
                      </Span>
                    </Space>
                  </FlexBox>
                </FlexBox>
              </Space>
            </Width>
            <Width size="20%">
              <MemoTextField
                applicationId={a.id}
                memo={a.memo}
                onSuccess={(message) => setSnack({...snack, type: 'success', message: message})}
                onError={(message) => setSnack({...snack, type: 'error', message: message})}
              />
            </Width>
          </FlexBox>
        </Card>
      ))}
      {
        isChangedResult && (
          <div className="fixed-bottom">
            <FlexBox width="100%" height="7.5rem" backColor="#FDE8E9" content="end">
              <Space margin="0 2rem 0 0">
                <Span fontSize="13px">合格または不合格の場合、受講期間終了後になると、参加者のマイページに評価結果が表示されるようになります</Span>
              </Space>
              <FlexBox>
                <Button
                  color={`${isProcessing ? "#333" : "#fff"}`}
                  backColor={`${isProcessing ? "#fff" : "#005BAC"}`}
                  border={`${isProcessing ? "1px solid #D7D7D7" : "none"}`}
                  onClick={handleSubmit}
                >
                  <Width size="10rem">
                    <Space margin="1rem">
                      <FlexBox content="center">
                        <span>保存</span>
                      </FlexBox>
                    </Space>
                  </Width>
                </Button>
                <Space margin="1rem"/>
                <Button
                  color={`${isProcessing ? "#333" : "#fff"}`}
                  backColor={`${isProcessing ? "#fff" : "#005BAC"}`}
                  border={`${isProcessing ? "1px solid #D7D7D7" : "none"}`}
                  onClick={handleCancel}
                >
                  <Width size="10rem">
                    <Space margin="1rem">
                      <FlexBox content="center">
                        <span>キャンセル</span>
                      </FlexBox>
                    </Space>
                  </Width>
                </Button>
              </FlexBox>
            </FlexBox>
          </div>
        )
      }
    </>
  )
}

export default index