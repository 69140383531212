import React from 'react'
import Card from '../atoms/Card';
import Thumbnail from './user/shared/Thumbnail';
import RecruitingStatusBadge from './user/shared/RecruitingStatusBadge';
import LeftRecruitingAmountBadge from './user/shared/LeftRecruitingAmountBadge';
import CourseDatePeriod from './user/shared/CourseDatePeriod';
import TrainerCard from './user/shared/TrainerCard';
import Container from '../atoms/Container';

function CourseOverviewCard({course, isShowDescriptionLink}) {
  const thumbnailAnnotationMessage = () => {
    if(course.is_have_priority_apply_right) {
      return null;
    }else if (course.is_already_applied) {
      return '申込済';
    }else if (course.is_seat_full && !course.is_already_canceled) {
      return 'SOLD OUT';
    } else {
      return null;
    }
  }
  return (
    <Card>
      <div className={'row-area row-area_xlarge row-area_ai-start mobile-only_col-area mobile-only_ai_center w-100 justify-content-between'}>
        <Thumbnail
          flameClass="thumbnail-area thumbnail-area_square"
          imgPath={course.course_template.icon_link}
          course={course}
          annotation={thumbnailAnnotationMessage()}
        />
        <div className={'col-area col-area_xlarge mobile-only_ai_center w-100'}>
          <div className={'row-area row-area_xlarge mobile-only_col-area justify-content-between'}>
            <div>
              <RecruitingStatusBadge course={course}/>
              <LeftRecruitingAmountBadge course={course}/>
            </div>
            <CourseDatePeriod course={course}/>
          </div>
          <div>
            <h1 className="mobile-only_ta_center">{course.name}</h1>
          </div>
          <div className={'d-flex justify-content-between'}>
            <div className="d-flex justify-content-between">
              <div className="mr-10">
                <TrainerCard course={course}/>
              </div>
              <Container>
                <span className="material-icons">location_on</span>
                <span>{course.venue.label}</span>
              </Container>
            </div>
            {isShowDescriptionLink && (
              <div className="user_course_index_link">
                <span>
                  <a className="button" href={course.get_link}>
                    <span className="user_course_index_link_text">詳細</span>
                  </a>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CourseOverviewCard