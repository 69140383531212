import React, {useState} from 'react'
import Card from '../../../../atoms/Card';
import RadioButton from '../../../../form/RadioButton';
import Space from '../../shared/Space';
import FormTypeRadio from './FormTypeRadio';
import SelfApplicationForm from './SelfApplicationForm';
import ProxyApplicationForm from './ProxyApplicationForm';
import SeatApplicationForm from './SeatApplicationForm';

function ApplicationForm({
  course,
  tech_student,
  onChange,
  formType,
  onChangeFormType,
  validationMessage,
  proxyMailAddresses,
  onChangeProxyMailAddressHandler,
  mailAddressValidationMessages,
  seatReservation,
  onChangeSeatReservation,
  seatReservationValidationMessage
}) {
  return (
    <Card>
      <FormTypeRadio formType={formType} onChange={onChangeFormType}/>
      <Space margin="1rem 0"/>
      <div className="card_in_card">
        <div className="card">
          <div className="card_content">
            <div className="card_content_section">
              {formType === '参加者本人が申し込む' && (
                <SelfApplicationForm
                  tech_student={tech_student}
                  onChange={onChange}
                  validationMessage={validationMessage}
                />
              )}
              {formType === '本人の代理で申し込む' && (
                <ProxyApplicationForm
                  course={course}
                  proxyMailAddresses={proxyMailAddresses}
                  onChangeProxyMailAddressHandler={onChangeProxyMailAddressHandler}
                  mailAddressValidationMessages={mailAddressValidationMessages}
                />
              )}
              {formType === '席のみ申し込む' && (
                <SeatApplicationForm
                  course={course}
                  proxyMailAddresses={proxyMailAddresses}
                  seatReservation={seatReservation}
                  onChangeSeatReservation={onChangeSeatReservation}
                  seatReservationValidationMessage={seatReservationValidationMessage}
                />
              )}

            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ApplicationForm