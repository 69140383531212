import React from 'react'

function TrainerCard({course}) {
  return (
    <div className="row-area row-area_small">
      <span className="thumbnail-area thumbnail-area_circle">
        <img src={course.main_trainer_photo_link}/>
        <span>{course.main_trainer_name}</span>
      </span>
    </div>
  )
}

export default TrainerCard