import React from 'react'

function FormTypeRadio({formType, onChange}) {
  return (
    <div className="card_in_card">
      <div className="card">
        <div className="card_content">
          <div className="card_content_section">
            <h2 className="tech_user_course_show_subtitle">申込方法</h2>
            <div className="user_course_show_radio_form">
              <span className="user_course_show_radio_form_option" onClick={(e) => onChange('参加者本人が申し込む')}>
                <input type={"radio"} checked={formType === '参加者本人が申し込む'}/>
                <label>参加者本人が申し込む</label>
              </span>
              <span className="user_course_show_radio_form_option" onClick={(e) => onChange('本人の代理で申し込む')}>
                <input type={"radio"} checked={formType === '本人の代理で申し込む'}/>
                <label>本人の代理で申し込む</label>
              </span>
              <span className="user_course_show_radio_form_option" onClick={(e) => onChange('席のみ申し込む')}>
                <input type={"radio"} checked={formType === '席のみ申し込む'}/>
                <label>席のみ申し込む</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormTypeRadio