import React, {useState, useMemo, useEffect} from 'react';
import {isDateFormatString} from '../../../../../shared/text';
import { useCourses } from '../../hooks';
import Switch from '@mui/material/Switch';
import DateDecorator from '../../../../DateDecorator';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButton from '@mui/material/ToggleButton';
import LoadingIcon from '../../../../LoadingIcon';
import CourseList from './CourseList';
import Pagination from '@mui/material/Pagination';
import FlexBox from '../../../../atoms/FlexBox';
import Width from '../../../../atoms/Width';
import Button from '../../../../atoms/Button';
import Space from '../../../../atoms/Space';
import Container from '../../../../atoms/Container';

const UserCourseIndex = ({ params, courseKinds }) => {
  const defaultSearchFrom = useMemo(() => {
    if (params.isPast === 'true') {
      return ''
    } else if (isDateFormatString(params.from)){
      return new DateDecorator(new Date(params.from)).param()
    } else {
      return new DateDecorator(new Date()).param()
    }
  }, [params.from])

  const defaultSearchTo = useMemo(() => {
    if (params.isPast === 'true') {
      return new DateDecorator(new Date()).prevDay().param()
    } else if (isDateFormatString(params.to)){
      return new DateDecorator(new Date(params.to)).param()
    } else {
      return new DateDecorator(new Date(2999, 1, 1)).param()
    }
  }, [params.to, params.isPast])
  
  const [searchKey, setSearchKey] = useState({
    from: defaultSearchFrom,
    to: defaultSearchTo,
    courseKindIds: params.courseKindIds?.split(',').map(s => Number(s)) || [],
    page: 1,
  })
  const setPastCourseSearch = (e) => {
    const today = new Date()
    if (e.target.checked) {
      setSearchKey({...searchKey, from: '', to: new DateDecorator(today).prevDay().param()})
    } else {
      setSearchKey({
        ...searchKey,
        from: new DateDecorator(today).param(),
        to: new DateDecorator(new Date(2999, 1, 1)).param(),
      })
    }
  }
  const { courses, pageSize, error, isLoading } = useCourses(searchKey)
  
  return (
    <div className={"section-area"}>
      <div className={"card"}>
        <h1>公開中のコース</h1>
        <div className={"heading-area"}>
          <div className={"title-area d-flex justify-content-between mr-0"}>
            <div className="justify-content-between d-flex w-100">
              <div className="flex-wrap">
                {courseKinds.map((c) => (
                  <ToggleButton
                    value={c.value}
                    selected={searchKey.courseKindIds.includes(c.value)}
                    onChange={() => {
                      if(searchKey.courseKindIds.includes(c.value)) {
                        setSearchKey({...searchKey, courseKindIds: searchKey.courseKindIds.filter(ck => ck !== c.value), page: 1})
                      }else {
                        setSearchKey({...searchKey, courseKindIds: [...searchKey.courseKindIds, c.value], page: 1})
                      }
                    }}
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      margin: '.5rem',
                      "&.Mui-selected, &.Mui-selected:hover": {
                        color: "white",
                        backgroundColor: '#7894c3'
                      },
                    }}
                  >
                    <span>{c.label}</span>
                  </ToggleButton>
                ))}
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch onChange={setPastCourseSearch} defaultChecked={params.isPast === 'true'}/>
                  }
                  label="過去のコースを検索"
                  sx={{
                    width: 200,
                    color: 'success.main',
                    m: 0,
                    '& .MuiTypography-root': {
                      fontSize: '1.5rem',
                      fontWeight: 'bold'
                    },
                  }}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        { isLoading && <LoadingIcon/> }
        { !isLoading && (
          <CourseList viewCourses={courses}/>
        )}
        { courses && courses?.length !== 0 && (
          <FlexBox content="center">
            <Button
              onClick={() => setSearchKey({...searchKey, page: searchKey.page === 1 ? 1 : searchKey.page - 1})}
              disabled={searchKey.page === 1}
              width="4rem"
              height="4rem"
              backColor={searchKey.page === 1 ? "#FFF" : "#205DC9"}
              color={searchKey.page === 1 ? "#777" : "#fff"}
            >
              <Container width="100%" height="100%">
                <span class="material-icons">chevron_left</span>
              </Container>
            </Button>
            <Space margin="2rem"/>
            <Button
              onClick={() => setSearchKey({...searchKey, page: searchKey.page === pageSize ? pageSize : searchKey.page + 1})}
              disabled={searchKey.page === pageSize}
              width="4rem"
              height="4rem"
              backColor={searchKey.page === pageSize ? "#FFF" : "#205DC9"}
              color={searchKey.page === pageSize ? "#777" : "#fff"}
            >
              <Container width="100%" height="100%">
                <span class="material-icons">chevron_right</span>
              </Container>
            </Button>
          </FlexBox>
        )}
      </div>
    </div>
  )
}

export default UserCourseIndex;
