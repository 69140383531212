import Axios from '../../../../../Axios';

export const handleDirectSubmit = (
  processing,
  course_id,
  handleMessage,
  handleProcessing,
  postData,
  formType,
) => {
  if(processing) {
    return;
  }
  handleProcessing(true);
  Axios.post(`/tech/user/course_applications`, { 
    tech_course_id: course_id,
    form_type: formType,
    ...postData

  })
    .then(({data}) => {
      handleProcessing(false);
      window.location.href = `/tech/user/course_applications/complete?tech_course_id=${course_id}`;
    }).catch(err => {
      handleMessage({text: `システムエラーが発生しました。原因：${err.response.data.error_message}`, type: 'error'});
      handleProcessing(false);
    });
}

export const handleAddWatchList = (processing, course_id, handleMessage, handleProcessing, handleWatching) => {
  if(processing) {
    return;
  }
  handleProcessing(true);
  Axios.post(`/tech/user/course_watchlists/toggle_list`, { tech_user_course_watchlist: {tech_course_id: course_id}})
    .then(({data}) => {
      handleMessage({text: `登録しました`, type: 'notice'});
      handleProcessing(false);
      handleWatching(true);
    }).catch(err => {
      handleMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'error'});
      handleProcessing(false);
    });
}

export const handleResetWatchList = (processing, course_id, handleMessage, handleProcessing, handleWatching) => {
  if(processing) {
    return;
  }
  handleProcessing(true);
  Axios.post(`/tech/user/course_watchlists/toggle_list`, { tech_user_course_watchlist: {tech_course_id: course_id}})
    .then(({data}) => {
      handleMessage({text: `解除しました`, type: 'notice'});
      window.location.reload();
    }).catch(err => {
      handleMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'error'});
      handleProcessing(false);
    });
}

export const appliableAmount = (course) => (course.left_recruiting_amount >= 7 ? 7 : course.left_recruiting_amount)

export const mailAddressesValidation = (arr, setMailAddressValidationMessages, handleMessage) => {
  const validationMessages = arr.map((address) => (address === '' || address.match(/.+@.+\..+/)) ? '' : 'sample@odde.comの形式で入力してください');
  if(arr.filter(address => (address !== '')).length === 0) {
    handleMessage({text: `申込者のメールアドレスは１つ以上入力してください`, type: 'error'});
    return false
  }else if (validationMessages.filter(message => (message !== '')).length > 0) {
    setMailAddressValidationMessages(validationMessages);
    return false
  } else {
    return true
  }
} 

export const genderLabel = (tech_student) => {
  if (String(tech_student.gender_enum_id) === '1') {
    return '男性';
  } else if(String(tech_student.gender_enum_id) === '2') {
    return '女性';
  }else {
    return '回答なし'
  }
}

export const accountTypeLabel = (tech_student) => {
  if (String(tech_student.tech_student_classification_enum_id) === '18') {
    return '社会人';
  } else if(String(tech_student.tech_student_classification_enum_id) === '17') {
    return '学生';
  }
}

export const moveToConfirmStep = (
  student,
  postData,
  handleProcessing,
  handlePageStep,
  handleMessage,
  handleValidationMessage,
  setMailAddressValidationMessages,
  processing,
  formType,
  setSeatReservationValidationMessage,
  maxReservationAmount
) => {
  if(processing) {
    return;
  }
  handleProcessing(true);

  if(formType === '参加者本人が申し込む') {
    Axios.patch(`/tech/user/students/${student.id}/update_by_course_form`, { ...postData })
    .then(({data}) => {
      handlePageStep("確認ページ");
      handleProcessing(false);
      handleMessage(null);
      window.scrollTo(0,0);
    }).catch(err => {
      handleValidationMessage(err.response.data)
      handleMessage({text: `申込情報を正しく入力してください`, type: 'error'});
      handleProcessing(false);
    });
  }else if (formType === '本人の代理で申し込む') {
    if (!mailAddressesValidation(postData.proxy_mail_addresses, setMailAddressValidationMessages, handleMessage)){
      handleProcessing(false);
      return;
    }
    handleMessage(null);
    handlePageStep("確認ページ");
    window.scrollTo(0,0);
    handleProcessing(false);
  }else if(formType === '席のみ申し込む') {
    if (!seatReservationValidation(postData.seat_reservation, setSeatReservationValidationMessage, handleMessage, maxReservationAmount)){
      handleProcessing(false);
      return;
    }
    handleMessage(null);
    handlePageStep("確認ページ");
    window.scrollTo(0,0);
    handleProcessing(false);
  }
}

export const seatReservationValidation = (value, setSeatReservationValidationMessage, handleMessage, maxReservationAmount) => {
  const target = Number(convertDoubleCharToSingle(value));
  if(!isPositiveInteger(target)) {
    setSeatReservationValidationMessage('正の整数を入力してください');
    return false
  } else if ( target > maxReservationAmount) {
    setSeatReservationValidationMessage(`${maxReservationAmount} 以下の数値を入力してください`);
    return false
  } else {
    return true
  }
} 

export const convertDoubleCharToSingle = (str) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 65248);
  });
}

export const isPositiveInteger = (n) => {
  return Number.isInteger(n) && n > 0;
}