import React, {useState} from 'react';
import {formatDatePeriod, formatDateSlash, formatTimePeriod, formatDateTimeSlash } from '../../../../../shared/date';
import {textUrlToLink, convertBreakToBr} from '../../../../../shared/text';
import ModalButton from '../../../../ModalButton';
import Axios from '../../../../Axios';
import Alert from "../../../../Alert";
import {
  handleDirectSubmit,
  handleAddWatchList,
  handleResetWatchList,
  moveToConfirmStep,
  appliableAmount,
  convertDoubleCharToSingle
} from './func';
import Breadcrumb from "../../shared/Breadcrumb";
import SectionArea from '../../../../atoms/SectionArea';
import Card from '../../../../atoms/Card';
import Thumbnail from '../../shared/Thumbnail';
import Badge from '../../shared/Badge';
import RecruitingStatusBadge from '../../shared/RecruitingStatusBadge';
import LeftRecruitingAmountBadge from '../../shared/LeftRecruitingAmountBadge';
import TrainerCard from '../../shared/TrainerCard';
import NoticeMessage from '../../shared/NoticeMessage';
import CardSection from '../../../../atoms/CardSection';
import MainSideArea from '../../shared/MainSideArea';
import MainSideAreaMain from '../../shared/MainSideAreaMain';
import CourseDescription from './CourseDescription';
import ApplicationForm from './ApplicationForm';
import ApplicationDescription from './ApplicationDescription';
import Stepper from '../../../Stepper';
import CourseOverviewCard from '../../../CourseOverviewCard';
import Space from '../../shared/Space';
import DateDecorator from '../../../../DateDecorator';
import Span from '../../../../atoms/Span';

const Index = ({
  course,
  is_logged_in,
  is_tec_user_account,
  student,
}) => {
  const [message, setMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState({});
  const [isWatching, setIsWatching] = useState(course.is_already_added_to_watch_list);
  const [processing, setProcessing] = useState(false);
  const [pageStep, setPageStep] = useState('コース詳細');
  const [postData, setPostData] = useState(
    {
      tech_student: {
        ...student,
        ...student?.account,
        is_send_mail_magazine: student?.is_send_mail_magazine !== null ? student?.is_send_mail_magazine?.toString() : null,
        first_tel: student?.account?.first_tel_number,
        second_tel: student?.account?.second_tel_number,
        third_tel: student?.account?.third_tel_number,
        organization_name: student?.tech_corporation?.name,
        organization_name_kana: student?.tech_corporation?.name_kana,
      },
      proxy_mail_addresses: [...Array(appliableAmount(course))].map(() => ''),
      seat_reservation: '1',
    }
  )
  const [formType, setFormType] = useState('参加者本人が申し込む')
  const [mailAddressValidationMessages, setMailAddressValidationMessages] = useState(
    [...Array(appliableAmount(course))].map(() => '')
  )
  const [seatReservationValidationMessage, setSeatReservationValidationMessage] = useState('');
  const buttonClass = () => {
    return processing ? "button button_conversion button_conversion_disable" : "button button_conversion"
  }

  const onChangeHandler = (e) => {
    setPostData({...postData, tech_student: {...postData.tech_student, [e.target.name]: e.target.value}})
  }

  const onChangeProxyMailAddressHandler = (e) => {
    setPostData({...postData, proxy_mail_addresses: proxyAddressFormNameConvert(e)})
  }

  const proxyAddressFormNameConvert = (e) => {
    const idx = Number(e.target.name.replace("proxy_application_address_", ""))
    const temp = postData.proxy_mail_addresses;
    temp[idx] = e.target.value;
    return temp;
  }

  const onChangeSeatReservation = (e) => {
    setPostData({...postData, seat_reservation: convertDoubleCharToSingle(e.target.value)})
  }

  const isCanApply = () => (
    is_logged_in && 
    is_tec_user_account && 
    !course.is_already_applied &&
    (course.is_can_apply || course.is_have_priority_apply_right)
  )

  const applicationAmount = () => {
    if(formType === '参加者本人が申し込む') {
      return 1
    } else if (formType === '本人の代理で申し込む') {
      return postData.proxy_mail_addresses.filter(Boolean).length
    } else if (formType === '席のみ申し込む') {
      return Number(postData.seat_reservation)
    } else {
      return 0
    }
  }

  const subTotalPrice = () => {
    return course.tax_included_price * applicationAmount()
  }

  const totalDiscount = () => {
    if (course.course_template.is_discount) {
      return (
        Math.floor(applicationAmount() / course.course_template.discount_member_set) * (course.course_template.discount_percent / 100 * course.tax_included_price) * course.course_template.discount_member_set
      )
    } else {
      return 0
    }
  }

  const totalPrice = () => {
    return subTotalPrice() - totalDiscount()
  }

  const priceLabel = (price) => {
    if (course.price_symbol === '¥') {
      return `${course.price_symbol} ${price.toLocaleString()}（税込）`
    } else {
      return `${course.price_symbol} ${price.toLocaleString()}（tax included）`
    }
  }



  return(
    <>
      <Breadcrumb list={[{link: "/tech/user/courses", label: "コース"}, {label: "コースの詳細"}]}/>
      <SectionArea>
        <CourseOverviewCard course={course} isShowDescriptionLink={false}/>
      </SectionArea>
      <SectionArea>
        {message && <NoticeMessage error={message.type === 'error'}>{message.text}</NoticeMessage>}
        <MainSideArea>
          <MainSideAreaMain>
            {isCanApply() && (
              <Stepper
                steps={['コース詳細', '申込フォーム', '確認ページ']}
                activeStep={pageStep}
                isShowPagingLink
                handleChangeStep={setPageStep}
              />
            )}
            {pageStep == "コース詳細" && <CourseDescription course={course}/>}
            {pageStep == "申込フォーム" && (
              <ApplicationForm 
                course={course}
                tech_student={postData.tech_student}
                onChange={onChangeHandler}
                formType={formType}
                onChangeFormType={setFormType}
                validationMessage={validationMessage}
                proxyMailAddresses={postData.proxy_mail_addresses}
                onChangeProxyMailAddressHandler={onChangeProxyMailAddressHandler}
                mailAddressValidationMessages={mailAddressValidationMessages}
                seatReservation={postData.seat_reservation}
                onChangeSeatReservation={onChangeSeatReservation}
                seatReservationValidationMessage={seatReservationValidationMessage}
                />
            )}
            {pageStep == "確認ページ" && (
              <ApplicationDescription 
                course={course}
                tech_student={postData.tech_student}
                formType={formType}
                postData={postData}
                />
            )}
          </MainSideAreaMain>
          <div className="main-side-area_side">
            <div className="card sticky_top">
              <div className="card_section">
                <div className="card_section_article">
                  <div className="tech_user_course_show_subtitle_2">コース料金</div>
                  <div className="tech_user_course_show_side_content_article">{course.price_with_tax_included_label}</div>
                  <div className="tech_user_course_show_subtitle_2">数量</div>
                  <div className="tech_user_course_show_side_content_article">{applicationAmount()}</div>
                  <div className="tech_user_course_show_subtitle_2">小計金額</div>
                  <div className="tech_user_course_show_side_content_article">{priceLabel(subTotalPrice())}</div>
                  {course.course_template.is_discount && (
                    <>
                      <div className="tech_user_course_show_subtitle_2">
                        <Span fontSize="1.4rem" fontWeight="bold" color="#E8204E">割引合計金額</Span>
                      </div>
                      <div className="tech_user_course_show_side_content_article">
                        <Span fontSize="1.6rem" color="#E8204E">{priceLabel(totalDiscount())}</Span>
                        
                      </div>
                      <Space margin="0 0 3rem 0">
                        <Span fontSize="14px" fontWeight="bold" color="#E8204E">{`${course.course_template.discount_member_set}名の申込毎に本体価格（税込価格）より${course.course_template.discount_percent}％割引`}</Span>
                      </Space>
                    </>
                  )}
                  <div className="tech_user_course_show_subtitle_2">合計金額</div>
                  <div className="tech_user_course_show_side_content_article">{priceLabel(totalPrice())}</div>

                  <div className="tech_user_course_show_subtitle_2">申込受付期間</div>
                  <div className="tech_user_course_show_side_content_article">
                    {course.recruiting_status === '募集中' && `${formatDateTimeSlash(course.apply_end_day)} まで`}
                    {course.recruiting_status != '募集中' && `${formatDateTimeSlash(course.apply_start_day)} ~ ${formatDateTimeSlash(course.apply_end_day)}`}
                  </div>
                  <div className="tech_user_course_show_subtitle_2">お支払い方法</div>
                  <div className="tech_user_course_show_side_content_article">{course.way_to_pay_label}</div>
                  <div className="tech_user_course_show_subtitle_2">お支払い期限</div>
                  <div className="tech_user_course_show_side_content_article">{`${formatDateTimeSlash(new Date(course.payment_due_date))} `}</div>
                  <div className="tech_user_course_show_subtitle_2">キャンセルポリシー</div>
                  <div className="tech_user_course_show_side_content_article">
                    <div className="break_word" dangerouslySetInnerHTML={{ __html: textUrlToLink(convertBreakToBr(course.cancel_policy_rule_text)) }} />
                  </div>
                  <div className="tech_user_course_show_subtitle_2">その他の同意事項</div>
                  <div className="tech_user_course_show_side_content_article">
                    <div className="tech_user_course_show_side_content_article_link">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.tech-kai.com/ja/terms_of_service/terms_of_service.pdf"
                        className="row-area row-area_small"
                      >
                        <span>利用規約</span>
                        <span className="material-icons tech_user_course_show_side_content_article_link">open_in_new</span>
                      </a>
                    </div>
                    <div className="tech_user_course_show_side_content_article_link">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.gainsline.com/ja/privacy_policy.pdf"
                        className="row-area row-area_small"
                      >
                        <span>プライバシーポリシー</span>
                        <span className="material-icons tech_user_course_show_side_content_article_link">open_in_new</span>
                      </a>
                    </div>
                    <div className="tech_user_course_show_side_content_article_link">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.tech-kai.com/ja/specified_commercial_transactions/specified_commercial_transactions.pdf"
                        className="row-area row-area_small"
                      >
                        <span>特定商取引法に基づく表示</span>
                        <span className="material-icons tech_user_course_show_side_content_article_link">open_in_new</span>
                      </a>
                    </div>
                  </div>
                </div>
                {
                  course.is_recruiting_status_active && (
                    <>
                      <div className="card_section_article">
                        {
                          !is_logged_in && (
                            <div className="col-area">
                              <span>
                                <button className="button button_conversion button_conversion_disable">申込みにはログインが必要です</button>
                              </span>
                              <span className="row-area row-area_small c_gray fs_s jc_center">
                                <span>
                                  <a href="/tech/user/students/login_form">ログイン</a>
                                </span>
                                <span> / </span>
                                <span><a href="/tech/user/students/activate_form">新規会員登録</a></span>
                              </span>
                            </div>
                          )
                        }
                        {
                          is_logged_in && is_tec_user_account && course.is_already_applied && !course.is_already_canceled && (
                            <span>
                              <button className="button button_conversion button_conversion_disable">申込済み</button>
                            </span>
                          )
                        }
                        {
                          isCanApply() && pageStep === 'コース詳細' && (
                            <span className='button' onClick={() => {setPageStep("申込フォーム"); window.scrollTo(0,0);}}>
                              申込手続きに進む
                            </span>
                          )
                        }
                        {
                          isCanApply() && pageStep === '申込フォーム' && (
                            <>
                              <div className="mb-2 text-danger">
                                <p className="font-weight-bold">{course.cancel_policy_text}</p>
                              </div>
                              <span
                                className='button'
                                onClick={
                                  () => moveToConfirmStep(
                                    student,
                                    postData,
                                    setProcessing,
                                    setPageStep,
                                    setMessage,
                                    setValidationMessage,
                                    setMailAddressValidationMessages,
                                    processing,
                                    formType,
                                    setSeatReservationValidationMessage,
                                    appliableAmount(course)
                                  )
                                }
                              >
                                申込内容を確認
                              </span>
                            </>
                          )
                        }
                        {
                          isCanApply() && course.is_can_cancel && pageStep === '確認ページ' && (
                            <>
                              <div className="mb-2 text-danger">
                                <p className="font-weight-bold">{course.cancel_policy_text}</p>
                              </div>
                              <span
                                className={processing ? buttonClass() : 'button'}
                                onClick={() => handleDirectSubmit(processing, course.id, setMessage, setProcessing, postData, formType)}
                              >
                                {processing ? '送信中...' : <span>上記に同意の上<br/>申込を確定する</span>}
                              </span>
                            </>
                          )
                        }
                        {
                          isCanApply() && !course.is_can_cancel && pageStep === '確認ページ' && (
                            <>
                              <div className="mb-2 text-danger">
                                <p className="font-weight-bold">{course.cancel_policy_text}</p>
                              </div>
                              {processing ? (
                                <span className={buttonClass()}>送信中...</span>
                              ): (
                                <ModalButton
                                  message={<span>上記に同意の上<br/>申込を確定する</span>}
                                  title="※注意事項"
                                  body="既にキャンセル可能期間を過ぎています。お申込み後はキャンセル不可となりますがよろしいですか。"
                                  confirmMessage='理解した上で申込む'
                                  submitButtonStyle="button"
                                  handleSubmit={() => handleDirectSubmit(processing, course.id, setMessage, setProcessing, postData, formType)}
                                  link
                                />
                              )
                            }
                            </>
                          )
                        }
                        {
                          is_logged_in && is_tec_user_account && !course.is_already_applied && !course.is_can_apply && !course.is_seat_full && !course.is_have_priority_apply_right && (
                            <span>
                              <button className="button button_conversion button_conversion_disable">{course.application_button_label}</button>
                            </span>
                          )
                        }
                        {
                          is_logged_in && !is_tec_user_account && (
                            <span>
                              <button className="button button_conversion button_conversion_disable">受講生アカウントで<br/>ログインしてください</button>
                            </span>
                          )
                        }
                      </div>
                      {
                        is_logged_in && is_tec_user_account && course.is_can_cancel && course.is_display_add_watch_list_button && (
                          <div className="card_section_article card_section_article_border">
                            <div className="col-area col-area_small ta_center">
                              {
                                isWatching && (
                                  <span
                                    className={processing ? "button button_conversion button_conversion_disable" : "button button_delete"}
                                    onClick={() => handleResetWatchList(processing, course.id, setMessage, setProcessing, setIsWatching)}
                                  >
                                    {processing ? '送信中...' : '空席待ちを解除する'}
                                  </span>
                                )
                              }
                              {
                                !isWatching && (
                                  <span 
                                    className={processing ? "button button_conversion button_conversion_disable" : "button button_conversion"}
                                    onClick={() => handleAddWatchList(processing, course.id, setMessage, setProcessing, setIsWatching)}
                                  >
                                    {processing ? '送信中...' : '空席待ちに登録する'}
                                  </span>
                                )
                              }
                            </div>
                            <Space margin="1rem 0 0 0 ">
                              <div className="tech_user_course_show_side_content_remarks">空席待ちに登録すると、キャンセルが出た際にメールで通知します。参加には別途申込が必要ですのでご注意ください。</div>
                            </Space>
                          </div>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </MainSideArea>
      </SectionArea>
    </>
  )
}

export default Index;