import React, {useState, useEffect} from 'react';
import TwoWayRow from '../../../atoms/TwoWayRow';
import Width from '../../../atoms/Width';
import CardSection from '../../../atoms/CardSection';
import CardSectionArticle from '../../../atoms/CardSectionArticle';
import Space from '../../../atoms/Space';
import FormComponent from '../../../form/Form';
import Card from '../../../atoms/Card';
import TextField from '../../../form/TextField';
import IncrementalSelect from '../../../form/IncrementalSelect';
import TextArea from '../../../form/TextArea';
import FileUploader from '../../../FileUploader';
import RadioButton from '../../../form/RadioButton';
import ColArea from '../../../atoms/ColArea';
import Button from '../../../atoms/Button';
import Span from '../../../atoms/Span';
import FlexBox from '../../../atoms/FlexBox';
import { handleSubmit, handleBack } from './func';
import { getTaxIncludedPrice } from '../../api';
import DateDecorator from '../../../DateDecorator';
import { stringToBoolean } from '../../../../shared/converter';
import Snackbar from '../../../shared/Snackbar';

function Form({
  course_template,
  course_kind_options,
  course_group_options,
  invoice_type_options,
  icon_options,
  currency_options,
  params,
  course_introduction_video_options
}) {
  console.log(course_introduction_video_options)
  const [formData, setFormData] = useState({
    ...course_template,
    is_discount: course_template.is_discount === null ? false : course_template.is_discount
  });
  const [errMessage, setErrorMessage] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [taxIncludedPrice, setTaxIncludedPrice] = useState(0);
  const [snack, setSnack] = useState({
    type: 'success',
    message: '',
    autoHideDuration: 2000,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
  });

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  }

  useEffect(() => {
    getTaxIncludedPrice(formData.currency_value, formData.currency_enum_id, new DateDecorator(new Date).param())
      .then((res) => {
        setTaxIncludedPrice(res.data.tax_included_value);
      })
  }, [formData.currency_enum_id, formData.currency_value]);

  useEffect(() => {
    setSnack({...snack, message: params.snack || ''})
  }, [])
  
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({...snack, message: ""})
  };

  return (
    <Width>
      <Snackbar
        open={snack.message !== ""}
        message={snack.message}
        anchorOrigin={snack.anchorOrigin}
        autoHideDuration={snack.autoHideDuration}
        type={snack.type}
        onClose={handleSnackClose}
      />
      <Space margin="0.2rem">
        <TwoWayRow>
          <Width size="75%">
            <Space padding="0 2rem 0 0">
              <Card padding="2rem">
                <CardSection>
                  <FormComponent withoutSubmit={true}>
                    <TextField
                      label="テンプレート名"
                      required={true}
                      value={formData.name}
                      nameKey={'name'}
                      placeholder={'Odd-e Japan開催のCSM研修'}
                      onChange={handleChange}
                      errorMessage={errMessage?.name && `テンプレート名${errMessage.name[0]}`}
                    />
                    <IncrementalSelect
                      label='コースの種類'
                      required={true}
                      options={course_kind_options}
                      nameKey={'tech_course_kind_id'}
                      selectedValue={formData.tech_course_kind_id ?? ''}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_course_kind_id && `コースの種類${errMessage.tech_course_kind_id[0]}`}
                      disabled={false}
                    />
                    <IncrementalSelect
                      label='授業群'
                      required={true}
                      options={course_group_options}
                      nameKey={'tech_lesson_group_id'}
                      selectedValue={formData.tech_lesson_group_id ?? ''}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_lesson_group_id && `授業群${errMessage.tech_lesson_group_id[0]}`}
                      disabled={false}
                    />
                    <TextArea
                      label="コース概要の説明１"
                      required={true}
                      value={formData.tech_course_overview_text_1}
                      nameKey={'tech_course_overview_text_1'}
                      placeholder={'コース概要１を入力してください'}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_course_overview_text_1 && `コース概要の説明１${errMessage.tech_course_overview_text_1[0]}`}
                    />
                    <FileUploader
                      label="コース概要の画像１"
                      name="upload_file_1_id"
                      required={false}
                      deffalt={formData.upload_file_1_id}
                      valid_extensions="image/jpeg,image/png"
                      hidePreview={false}
                      placeholder="画像ファイルを選択してください"
                      maxFileSize={1}
                      onChange={(v) => handleChange({target: {name: 'upload_file_1_id', value: v}})}
                    />
                    <FileUploader
                      label="コース概要の画像２"
                      name="upload_file_2_id"
                      required={false}
                      deffalt={formData.upload_file_2_id}
                      valid_extensions="image/jpeg,image/png"
                      hidePreview={false}
                      placeholder="画像ファイルを選択してください"
                      maxFileSize={1}
                      onChange={(v) => handleChange({target: {name: 'upload_file_2_id', value: v}})}
                    />
                    <TextArea
                      label="コース概要の説明２"
                      required={false}
                      value={formData.tech_course_overview_text_2}
                      nameKey={'tech_course_overview_text_2'}
                      placeholder={'コース概要２を入力してください'}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_course_overview_text_2 && `コース概要の説明２${errMessage.tech_course_overview_text_2[0]}`}
                    />
                    <IncrementalSelect
                      label='コース紹介動画'
                      required={false}
                      options={course_introduction_video_options}
                      nameKey={'tech_course_introduction_id'}
                      selectedValue={formData.tech_course_introduction_id ?? ''}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_course_introduction_id && `コース紹介動画${errMessage.tech_course_introduction_id[0]}`}
                      disabled={false}
                    />
                    <TextArea
                      label="アジェンダ"
                      required={true}
                      value={formData.tech_agenda_text}
                      nameKey={'tech_agenda_text'}
                      placeholder={'コースのアジェンダを入力してください'}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_agenda_text && `アジェンダ${errMessage.tech_agenda_text[0]}`}
                    />
                    <TextArea
                      label="受講者の要件"
                      required={true}
                      value={formData.tech_course_requirement_text}
                      nameKey={'tech_course_requirement_text'}
                      placeholder={'受講者要件を入力してください'}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_course_requirement_text && `受講者の要件${errMessage.tech_course_requirement_text[0]}`}
                    />
                    <IncrementalSelect
                      label='請求書のフォーマット'
                      required={true}
                      options={invoice_type_options}
                      nameKey={'tech_invoice_type_id'}
                      selectedValue={formData.tech_invoice_type_id ?? ''}
                      onChange={(e) => {
                        if (e.target.value === 113) { // 113: Gainsline
                          setFormData({...formData, currency_enum_id: 19, [e.target.name]: e.target.value}); // 19: 円
                        } else {
                          setFormData({...formData, currency_enum_id: 20, [e.target.name]: e.target.value}); // 19: ドル
                        }
                      }}
                      errorMessage={errMessage?.tech_invoice_type_id && `請求書のフォーマット${errMessage.tech_invoice_type_id[0]}`}
                      disabled={false}
                    />
                    {(formData.currency_enum_id === null || formData.currency_enum_id === undefined) && (
                      <TextField
                        label="金額"
                        required={true}
                        type={'number'}
                        value={formData.currency_value}
                        nameKey={'currency_value'}
                        placeholder={'支払通貨種別を先に選択してください'}
                        onChange={handleChange}
                        disabled
                      />
                    )}
                    {formData.currency_enum_id == 19 && (
                      <TextField
                        label="金額(円)"
                        required={true}
                        type={'number'}
                        value={formData.currency_value}
                        nameKey={'currency_value'}
                        placeholder={'10000'}
                        onChange={handleChange}
                        errorMessage={errMessage?.currency_value && `金額(円)${errMessage.currency_value[0]}`}
                      />
                    )}
                    {formData.currency_enum_id == 20 && (
                      <TextField
                        label="金額(ドル)"
                        required={true}
                        type={'number'}
                        step={"0.01"}
                        value={formData.currency_value}
                        nameKey={'currency_value'}
                        placeholder={'100.00'}
                        onChange={handleChange}
                        errorMessage={errMessage?.currency_value && `金額(ドル)${errMessage.currency_value[0]}`}
                      />
                    )}
                    <tr>
                      <td>
                        {formData.currency_enum_id == 19 && <span>税込価格：{taxIncludedPrice}</span>}
                        {formData.currency_enum_id == 20 && <span>Tax Included Price：{taxIncludedPrice}</span>}
                        {(formData.currency_enum_id == 19 || formData.currency_enum_id == 20) && <Space margin="1rem 0 0 0"><span>※税込価格はコース開催時点の税率で再計算されます</span></Space>}
                      </td>
                    </tr>
                    <RadioButton
                      label={'割引の有無'}
                      required={true}
                      options={[{value: false, name: '無'}, {value: true, name: '有'}]}
                      nameKey={'is_discount'}
                      selectedValue={stringToBoolean(formData.is_discount)}
                      onChange={handleChange}
                      errorMessage={errMessage?.is_discount && `割引の有無${errMessage.is_discount[0]}`}
                    />
                    {stringToBoolean(formData.is_discount) && (
                      <>
                        <TextField
                          label="割引の表示名"
                          required={true}
                          value={formData.discount_label}
                          nameKey={'discount_label'}
                          placeholder={'例）ペア割引'}
                          onChange={handleChange}
                          errorMessage={errMessage?.discount_label && `割引の表示名${errMessage.discount_label[0]}`}
                        />
                        <TextField
                          label="割引の適用人数（人毎）"
                          required={true}
                          type={'number'}
                          value={formData.discount_member_set}
                          nameKey={'discount_member_set'}
                          placeholder={'2'}
                          onChange={handleChange}
                          errorMessage={errMessage?.discount_member_set && `割引の適用人数（人毎）${errMessage.discount_member_set[0]}`}
                        />
                        <TextField
                          label="割引の値（％）"
                          required={true}
                          type={'number'}
                          value={formData.discount_percent}
                          nameKey={'discount_percent'}
                          placeholder={'20'}
                          onChange={handleChange}
                          errorMessage={errMessage?.discount_percent && `割引の値（％）${errMessage.discount_percent[0]}`}
                        />
                      </>
                    )}
                    <RadioButton
                      label={'申込完了メールへの請求書添付'}
                      required={true}
                      options={[{value: true, name: '添付する'}, {value: false, name: '添付しない'}]}
                      nameKey={'is_send_invoice'}
                      selectedValue={stringToBoolean(formData.is_send_invoice)}
                      onChange={handleChange}
                      errorMessage={errMessage?.is_send_invoice && `申込完了メールへの請求書添付${errMessage.is_send_invoice[0]}`}
                    />
                    <IncrementalSelect
                      label='コースアイコン'
                      required={true}
                      options={icon_options}
                      nameKey={'tech_document_id'}
                      selectedValue={formData.tech_document_id ?? ''}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_document_id && `コースアイコン${errMessage.tech_document_id[0]}`}
                      disabled={false}
                    />
                    <TextArea
                      label="注意事項"
                      required={true}
                      value={formData.tech_caution_text}
                      nameKey={'tech_caution_text'}
                      placeholder={'注意事項を入力してください'}
                      onChange={handleChange}
                      errorMessage={errMessage?.tech_caution_text && `注意事項${errMessage.tech_caution_text[0]}`}
                    />
                  </FormComponent>
                </CardSection>
              </Card>
            </Space>
          </Width>
          <Width size="25%">
            <div className={"card sticky_top"}>
              <CardSection>
                <CardSectionArticle>
                  <h1>ページの操作</h1>
                </CardSectionArticle>
              </CardSection>
              <CardSection>
                <CardSectionArticle>
                  <ColArea>
                    <Button
                      disabled={isProcessing}
                      onClick={() => handleSubmit(
                        formData,
                        isProcessing,
                        setIsProcessing,
                        setErrorMessage,
                        setSnack
                      )}
                      height="4rem"
                      color={`${isProcessing ? "#333" : "#fff"}`}
                      backColor={`${isProcessing ? "#fff" : "#005BAC"}`}
                      border={`${isProcessing ? "1px solid #D7D7D7" : "none"}`}
                    >
                      <Space padding="1rem">
                        <FlexBox content="center">
                          <Span fontWeight="bold">
                            {isProcessing ? '実行中...' : "入力した内容で登録"}
                          </Span>
                        </FlexBox>
                      </Space>
                    </Button>
                    <Button disabled={isProcessing} onClick={() => handleBack(isProcessing)} height="4rem" color="#333" backColor="#D7D7D7" hoverBackColor="#a9a9a9">
                      <Space padding="1rem">
                        <FlexBox content="center">
                          <Span fontWeight="bold">
                            {isProcessing ? '実行中...' : "入力した内容を破棄"}
                          </Span>
                        </FlexBox>
                      </Space>
                    </Button>
                  </ColArea>
                </CardSectionArticle>
              </CardSection>
            </div>
          </Width>
        </TwoWayRow>
      </Space>
    </Width>
  )
}

export default Form;